<template>
  <div class="feed">
    <div class="container">
      <div class="header">
        <h1>Yun—v1.0</h1>
      </div>
      
      <div class="nav-menu">
        <router-link to="/" class="nav-item">Home</router-link>
        <router-link to="/feed" class="nav-item">Feed</router-link>
        <router-link to="/system-status" class="nav-item">System Status</router-link>
      </div>

      <div class="content">
        <div class="feed-items">
          <div class="feed-item" v-for="(item, index) in feedItems" :key="index">
            <p class="timestamp">{{ item.timestamp }}</p>
            <p class="message">{{ item.message }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Feed',
  data() {
    return {
      feedItems: [
        {
          timestamp: '2024-03-20 10:00',
          message: 'System initialized successfully.'
        },
        {
          timestamp: '2024-03-20 10:01',
          message: 'All systems operational.'
        }
      ]
    }
  }
}
</script>

<style scoped>
.feed {
  min-height: 100vh;
}

.header h1 {
  font-size: 34px;
  font-weight: normal;
  margin-bottom: 30px;
}

.nav-menu {
  margin-bottom: 60px;
}

.nav-item {
  margin-right: 30px;
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
  opacity: 0.8;
  transition: opacity 0.3s;
}

.nav-item:hover {
  opacity: 1;
}

.feed-item {
  margin-bottom: 20px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

.timestamp {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  margin-bottom: 8px;
}

.message {
  color: #ffffff;
}
</style> 